<template>
  <div class="bgcard">
    <div class="headerMsg">
      <button class="addbtn" @click="adduser">
        <i class="el-icon-setting" style="font-size: 0.16rem"></i>
        <span style="white-space: nowrap">{{ $t("lang.Alarmsetting") }}</span>
      </button>
      <div class="searchlist" >
        <div class="select_b" style="margin-left: 0.2rem; margin-bottom: 0.1rem">
          <span style="color: #bbbbbb">{{ $t("lang.AlarmType") }}：</span>
          <el-select
            style="height: 0.4rem; width: 1.1rem"
            v-model="value"
            :placeholder="$t('lang.Please')"
            popper-class="select_a"
            @change="types"
          >
            <el-option :label="$t('lang.whole')" :value="''"></el-option>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="select_b" style="margin-left: 0.2rem; margin-bottom: 0.1rem">
          <span style="color: #bbbbbb">{{ $t("lang.Alarmlevel") }}：</span>
          <el-select
            style="height: 0.4rem; width: 1.1rem"
            v-model="value1"
            :placeholder="$t('lang.Please')"
            popper-class="select_a"
            @change="grades($event, 1)"
          >
            <el-option :label="$t('lang.whole')" :value="''"></el-option>
            <el-option :label="$t('lang.Generalalarm')" :value="1"></el-option>
            <el-option :label="$t('lang.majoralarm')" :value="2"></el-option>
            <el-option
              :label="$t('lang.Emergencyalarm')"
              :value="3"
            ></el-option>
          </el-select>
        </div>
        <div class="select_b" style="margin-left: 0.2rem; margin-bottom: 0.1rem">
          <span style="color: #bbbbbb">{{ $t("lang.alarmstatus") }}：</span>
          <el-select
            style="height: 0.4rem; width: 1.1rem"
            v-model="value1_a"
            :placeholder="$t('lang.Please')"
            popper-class="select_a"
            @change="grades($event, 2)"
          >
            <el-option :label="$t('lang.whole')" :value="''"></el-option>
            <el-option :label="$t('lang.Recovered')" :value="1"></el-option>
            <el-option :label="$t('lang.alarmli')" :value="2"></el-option>
          </el-select>
        </div>
        <div
          class="searchtime"
          style="margin-left: 0.2rem; margin-bottom: 0.1rem; margin-right: 0"
        >
          <span style="color: #bbbbbb">{{ $t("lang.Alarmtime") }}：</span>
          <el-date-picker
            v-model="time"
            type="daterange"
            :range-separator="$t('lang.to')"
            :start-placeholder="$t('lang.Startdate')"
            :end-placeholder="$t('lang.Enddate')"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            @change="picker"
          >
          </el-date-picker>
        </div>
        <div class="query">
          <div class="inputname" style="margin-left: 0.1rem; margin-bottom: 0.1rem">
            <input
              style="width: 1.8rem; font-size: 0.15rem"
              v-model="value2"
              :placeholder="$t('lang.PleaseenterthedevicenameorSn')"
            />
          </div>
          <div class="searchbtn">
            <button @click="search">
              <p>{{ $t("lang.search") }}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column prop="name" :label="$t('lang.name')" align="center">
        </el-table-column>
        <el-table-column :label="$t('lang.type')" align="center">
          <template #default="scope">
            <span v-if="scope.row.type == 1">{{
              $t("lang.Deviceofflinealarm")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.Severitylevel')" align="center">
          <template #default="scope">
            <span v-if="scope.row.warningClass == 1" style="color: #cdb323">{{
              $t("lang.Generalalarm")
            }}</span>
            <span v-if="scope.row.warningClass == 2" style="color: #a936d9">{{
              $t("lang.majoralarm")
            }}</span>
            <span v-if="scope.row.warningClass == 3" style="color: #fd201b">{{
              $t("lang.Emergencyalarm")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.describe')" align="center">
					<template #default="scope">
						<span>{{scope.row.desc |text}}</span>
					</template>
        </el-table-column>
        <el-table-column
          prop="happenTime"
          :label="$t('lang.Sendingtime')"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="recoverTime"
          :label="$t('lang.recoverytime')"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center"  v-if="tableData.list">
        <div class="allNum">{{ $t("lang.total") }}: {{ tableData.total }}</div>
        <el-pagination
          popper-class="paging"
          background
         
          layout="prev, pager, next"
          :total="tableData.total"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 告警设置 -->
    <el-dialog
      :visible.sync="addval"
      width="30%"
      top="2rem"
      :title="$t('lang.alarmmanagement')"
      :show-close="false"
      custom-class="seteqcs_a"
    >
      <div>
        <div class="binding">
          <label>{{ $t("lang.AlarmType") }}：</label>
          <el-select v-model="alarm.type" :placeholder="$t('lang.Please')" @change="gettypes">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="binding">
          <label>{{ $t("lang.describe") }}：</label>
          <el-input
            v-model="alarm.desc"
            :placeholder="$t('lang.Pleasefillinthealarmdescription')"
            @input="blur"
          ></el-input>
        </div>
        <div class="binding">
          <label>{{ $t("lang.Alarmlevels") }}：</label>
          <el-select
            v-model="alarm.warningClass"
            :placeholder="$t('lang.Pleaseselectalarmlevel')"
          >
            <el-option :label="$t('lang.Generalalarm')" :value="1"></el-option>
            <el-option :label="$t('lang.majoralarm')" :value="2"></el-option>
            <el-option
              :label="$t('lang.Emergencyalarm')"
              :value="3"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div>
        <span class="dialog-footer">
          <button class="button_a" @click="addval = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button class="button_a" @click="determine">
            <p>{{ $t("lang.determine") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as alarms from "@/api/alarm.js";
export default {
  data() {
    return {
      addval: false,
      value: "",
      value1: "",
      value1_a: "",
      value2: "",
      time: "",
      parsm: {
        page: 1,
        size: 10,
        type: "",
        warningClass: "",
        startTime: "",
        stopTime: "",
        nameOrMid: "",
        alarmStatus: "",
      },
      tableData: [],
      options: [],
      alarm: {
        type: "",
        desc: "",
        warningClass: "",
      },
			langs:false,
			alarmtime:null,
			addtype:[]
    };
  },
	created() {
		if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
			this.langs=true
		}else{
			this.langs=false
		}
	},
	filters:{
		text(val){
			let zhon = sessionStorage.getItem("language");
			//console.log(zhon)
			let text=''
			if(val){
				let sk=val
				if(zhon=='zhong'){
					let wq=sk.split('-')
					sk=wq[0]
				}else{
					let wq=sk.split('-')
					sk=wq[1]
					if(wq.length==1){
						sk=wq[0]
					}
				}
				text=sk
			}
			return text
		}
	},
  mounted() {
    this.getList();
		this.gettype();
		this.alarmtime=setInterval(()=>{
			if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
				this.langs=true
			}else{
				this.langs=false
			}
			this.options=[]
			this.addtype.forEach((item) => {
				let sk=item.name
				if(this.langs){
					let wq=sk.split('-')
					sk=wq[0]
				}else{
					let wq=sk.split('-')
					sk=wq[1]
					if(wq.length==1){
						sk=wq[0]
					}
				}
			  this.options.push({ value: item.id, label: sk });
			});
		},2000)
  },
	beforeDestroy() {
		if(this.alarmtime){
			clearInterval(this.alarmtime)
		}
	},
  methods: {
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //获取告警类型
    async gettype() {
      this.options = [];
      let res = await alarms.type();
      if (res) {
        // addtype.push(res.data[0]);
				res.data=res.data.splice(0,2)
				this.addtype=JSON.parse(JSON.stringify(res.data))
        res.data.forEach((item) => {
					let sk=item.name
					if(this.langs){
						let wq=sk.split('-')
						sk=wq[0]
					}else{
						let wq=sk.split('-')
						sk=wq[1]
						if(wq.length==1){
							sk=wq[0]
						}
					}
          this.options.push({ value: item.id, label: sk });
        });
      }
    },
    //获取当前告警类型下的告警信息
    async gettypes(e) {
      let res = await alarms.getType({ type: e });
      if (res.data) {
				let sk=res.data.desc
				if(this.langs){
					let wq=sk.split('-')
					sk=wq[0]
				}else{
					let wq=sk.split('-')
					sk=wq[1]
					if(wq.length==1){
						sk=wq[0]
					}
				}
        this.alarm.desc = sk;
        this.alarm.warningClass = res.data.warningClass;
        this.$forceUpdate();
      }else{
				this.alarm.desc = '';
				this.alarm.warningClass = '';
				this.$forceUpdate();
			}
    },
    //获取告警列表
    async getList() {
      let res = await alarms.list(this.parsm);
      if (res.data) {
        //console.log(res.data);
        this.tableData = res.data;
      }
      console.log(res);
    },
    //告警类型筛选
    types(e) {
      this.parsm.type = e;
      this.getList();
    },
    //告警级别、状态筛选
    grades(e, index) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (index == 1) {
        this.parsm.warningClass = e;
      } else {
        this.parsm.alarmStatus = e;
      }
      this.getList();
    },
    //时间筛选
    picker(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (e) {
        this.parsm.startTime = e[0];
        this.parsm.stopTime = e[1];
      } else {
        this.parsm.startTime = "";
        this.parsm.stopTime = "";
      }
      this.getList();
    },
    //名称筛选
    search() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.nameOrMid = this.value2;
      this.getList();
    },
    //打开告警设置模态框
    adduser() {
      this.addval = true;
      this.alarm.type = "";
      this.alarm.desc = "";
      this.alarm.warningClass = "";
    },
    //刷新输入框
    blur() {
      this.$forceUpdate();
    },
    //设置告警
    async determine() {
      console.log(this.alarm);
      if (this.alarm.type == "") {
        this.$message.error(this.$t("lang.Pleaseselectalarmtype"));
        return false;
      }
      if (this.alarm.desc == "") {
        this.$message.error(this.$t("lang.Pleasefillinthealarmdescription"));
        return false;
      }
      if (this.alarm.warningClass == "") {
        this.$message.error(this.$t("lang.Pleaseselectalarmlevel"));
        return false;
      }
      let res = await alarms.add(this.alarm);
      if (res) {
        this.$message.success(this.$t("lang.Submitted"));
        this.addval = false;
        this.getList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-input__inner{
  color: #bbbbbb !important;
}
.query .inputname {
  display: flex;
  margin-right: 0.2rem !important;
}
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}

.headerMsg {
  display: flex;
  justify-content: space-between;
	align-items: center;

  .addbtn {
    border: none;
    outline-style: none;
    // width: 126px;
    padding: 0 0.15rem;
    box-sizing: border-box;
    height: 0.4rem;
    background: #307b37;
    border-radius: 0.1rem;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 0.06rem;
    }
  }
}

.binding {
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
		display: inline-block;
    width: 1.2rem;
		text-align: right;
  }

  .el-input,
  .el-select {
    width: 3rem;
  }
}

.button_a {
  border: none;
  outline-style: none;
  width: 0.92rem;
  height: 0.46rem;
  background: #ededed;
  border: 0.01rem solid #438749;
  border-radius: 0.1rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #438749;
    line-height: 0.35rem;
    text-align: center;
  }
}

.button_a:nth-child(2) {
  width: 0.92rem;
  height: 0.46rem;
  background: #438749;
  border-radius: 0.1rem;
  margin-left: 0.2rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.35rem;
  }
}
</style>
