import request from '../utils/request.js'

//获取设备告警列表
export function list(data) {
	return request({
		url: `/warning/deviceWarningList`,
		method: 'get',
		params:data
	})
}
//获取服务器告警列表
export function lists(data) {
	return request({
		url: `/warning/serverWarningList`,
		method: 'get',
		params:data
	})
}
//获取告警类型
export function type() {
	return request({
		url: `/warning/warningClass`,
		method: 'get'
	})
}
//查询告警信息
export function getType(data) {
	return request({
		url: `/warning/type`,
		method: 'get',
		params:data
	})
}
//添加告警设置
export function add(data) {
	return request({
		url: `/warning/add`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}